import React from "react"

import Layout from "../components/layout"

import NuvoImage from "../components/NuvoImage"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from "@components/Button/Button"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Link } from "gatsby"

const ExparelPage = () => {
  let language = "en"

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title="Wisdom Teeth Consultation - Beacon Oral &amp; Maxillofacial Surgeons | Dental Implants &amp; Wisdom Teeth"
          description="It is your doctor’s top priority to make you feel well informed and comfortable with your treatment options. If you are watching this video, you are visiting our practice to have your third molars, also known as wisdom teeth, extracted."
        />

        <div className="columns wt-consult-page">
          <div className="column is-3"></div>
          <div className="column">
            <h1 className="has-text-centered">Wisdom Teeth Consultation</h1>
            <p>
              It is your doctor’s top priority to make you feel well informed
              and comfortable with your treatment options. If you are watching
              this video, you are visiting our practice to have your third
              molars, also known as wisdom teeth, extracted. In this
              presentation, we will review important information about this
              procedure. If you have any questions after watching this video,
              your doctor will be happy to answer them during your consultation.{" "}
            </p>

            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=uf7cebWrqb4`}
              overVideo={true}
              language={language}
              buttonClass="contained"
            >
              <NuvoImage
                useAR
                cloudName="nuvolum"
                publicId={
                  "BOMS/DEV/procedure-immediate-wisdom-teeth-video-thumb"
                }
                width="auto"
                className="theater-image"
              ></NuvoImage>
            </TheaterVideo>
          </div>
          <div className="column is-3"></div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default ExparelPage
